import { industriesIcon, productsLogo } from "routes/picPath";

const constContent = {
  pageTitle: "Branże",
  offerProducts: "Produkty w ofercie",
  btn: "Zapoznaj się \n z dedykowaną ofertą",
};

const industries = [
  {
    name: {
      extended: "Architektura i budownictwo",
      short: "aib",
      superShort: "aib",
    },
    fileName: true,
    icon: {
      src: industriesIcon.aib,
      alt: "ikona architektura i budownictwo",
    },
    text: [
      `Dedykowany Moduł Architektura SOWA wspiera prowadzenie postępowań specyficznych dla wydziałów architektury i budownictwa (np. pozwolenie na budowę/rozbiórkę, ustalenie lokalizacji inwestycji celu publicznego, ustalenie warunków zabudowy i zagospodarowania terenu) oraz automatyzuje proces generowania raportów (np. B-05, B-06, GUNB-3, Rejestr decyzji/wniosków LICP, Rejestr decyzji/wniosków WZ, Rejestr decyzji wniosków o pozwolenia na budowę i rozbiórkę).`,
      `Aplikacja GPT oprócz integracji z wieloma źródłami danych, pozwala na wyświetlanie danych z rejestrów, spraw oraz ewidencji utworzonych w aplikacji SOWA. Dzięki szerokiemu wyborowi narzędzi użytkownik może w łatwy sposób wybrać sąsiednie działki, wyświetlić i pobrać atrybuty wskazanych obiektów, wykonać analizy przestrzenne (przecinanie, zawieranie, bufor), ustalić położenie działki w Miejscowym Planie Zagospodarowania Przestrzennego lub wyszukać działkę w Ewidencji Gruntów i Budynków.`,
      `Aplikacja GPT umożliwia tworzenie portali dostępnych w wewnętrznej sieci urzędu – z zakresem warstw, narzędzi oraz danych niezbędnych do codziennej pracy oraz portali publicznych – udostępniających tematyczne informacje dla mieszkańców oraz inwestorów np. z zakresu architektury i budownictwa.`,
    ],
    products: [
      {
        src: productsLogo.gpt,
        name: "gpt",
        alt: "logo gpt",
        path: "/more/products#gpt",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.isdp,
        name: "isdp",
        alt: "logo isdp",
        path: "/more/products#isdp",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.sowa,
        name: "sowa",
        alt: "logo sowa",
        path: "/more/products#sowa",
        pathMobile: "/more/products",
      },
    ],
  },
  {
    name: {
      extended: "Geodezja i kartografia",
      short: "gik",
      superShort: "gik",
    },
    fileName: true,
    icon: {
      src: industriesIcon.gik,
      alt: "ikona geodezja i kartografia",
    },
    text: [
      `Dedykowany Moduł Geodezja SOWA wspiera prowadzenie postępowań specyficznych dla wydziałów geodezji i kartografii (np. rozgraniczenia nieruchomości, podział nieruchomości, informacje dla komorników, wypisy oraz wyrysy z operatu ewidencyjnego) oraz automatyzuje proces generowania raportów.`,
      `Dodatkowe wsparcie daje Moduł Ewidencja Miejscowości, Ulic i Adresów aplikacji SOWA, który jest narzędziem służącym do prowadzenia bazy punktów adresowych, ewidencji ulic oraz miejscowości, zgodnie w wymogami Rozporządzenia w sprawie ewidencji miejscowości ulic i adresów z dnia 9 stycznia 2012 r.`,
      `Aplikacja GPT oprócz integracji z wieloma źródłami danych, pozwala na wyświetlanie danych z rejestrów, spraw oraz ewidencji utworzonych w aplikacji SOWA. Dzięki szerokiemu wyborowi narzędzi użytkownik może w łatwy sposób wybrać sąsiednie działki, wyświetlić i pobrać atrybuty wskazanych obiektów, wykonać analizy przestrzenne (przecinanie, zawieranie, bufor), ustalić położenie działki w Miejscowym Planie Zagospodarowania Przestrzennego lub wyszukać działkę w Ewidencji Gruntów i Budynków.`,
      `Aplikacja GPT umożliwia tworzenie portali dostępnych w wewnętrznej sieci urzędu – z zakresem warstw, narzędzi oraz danych niezbędnych do codziennej pracy oraz portali publicznych – udostępniających tematyczne informacje dla mieszkańców oraz inwestorów np. z zakresu EGiB.`,
    ],
    products: [
      {
        src: productsLogo.gpt,
        name: "gpt",
        alt: "logo gpt",
        path: "/more/products#gpt",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.isdp,
        name: "isdp",
        alt: "logo isdp",
        path: "/more/products#isdp",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.sowa,
        name: "sowa",
        alt: "logo sowa",
        path: "/more/products#sowa",
        pathMobile: "/more/products",
      },
    ],
  },
  {
    name: {
      extended: "Gospodarka nieruchomościami",
      short: "gn",
      superShort: "gn",
    },
    fileName: true,
    icon: {
      src: industriesIcon.gn,
      alt: "ikona gospodarka nieruchomościami",
    },
    text: [
      `Moduł Gospodarka Nieruchomościami SOWA to autonomiczne, specjalistyczne rozszerzenie systemu SOWA, pozwalające na tworzenie oraz prowadzenie rejestrów związanych z zarządzaniem i obrotem nieruchomościami w ramach jednostki administracji samorządowej, państwowej itp.`,
      `Moduł ten posiada zintegrowany kalkulator cen sprzedaży lokali oraz możliwość sporządzania zestawień nieruchomości według jej przeznaczenia. Oprócz niewątpliwej zalety, jaką jest przechowywanie w spójnej i bezpiecznej formie wszystkich sprzedaży nieruchomości gminy lub Skarbu Państwa, dodatkowym atutem modułu jest mechanizm do automatycznego wykrywania wtórnego obrotu nieruchomościami, na które udzielona została bonifikata.`,
      `Aplikacja Podatkowa realizuje analizy porównawcze w oparciu o bazy danych Ewidencji Gruntów i Budynków oraz Ewidencji Podatków od Nieruchomości. Umożliwia ona uszczelnienie systemu poboru podatku od nieruchomości oraz wykazanie nieruchomości, w których występują rozbieżności mające wpływ na wymiar tego podatku.`,
      `Aplikacja GPT oprócz integracji z wieloma źródłami danych, pozwala na wyświetlanie danych z rejestrów, spraw oraz ewidencji utworzonych w aplikacji SOWA. Dzięki szerokiemu wyborowi narzędzi użytkownik może w łatwy sposób wybrać sąsiednie działki, wyświetlić i pobrać atrybuty wskazanych obiektów, wykonać analizy przestrzenne (przecinanie, zawieranie, bufor), ustalić położenie działki w Miejscowym Planie Zagospodarowania Przestrzennego lub wyszukać działkę w Ewidencji Gruntów i Budynków.`,
      `Aplikacja GPT umożliwia tworzenie portali dostępnych w wewnętrznej sieci urzędu – z zakresem warstw, narzędzi oraz danych niezbędnych do codziennej pracy oraz portali publicznych – udostępniających tematyczne informacje dla mieszkańców oraz inwestorów np. z zakresu gospodarki nieruchomościami.`,
    ],
    products: [
      {
        src: productsLogo.gpt,
        name: "gpt",
        alt: "logo gpt",
        path: "/more/products#gpt",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.isdp,
        name: "isdp",
        alt: "logo isdp",
        path: "/more/products#isdp",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.sowa,
        name: "sowa",
        alt: "logo sowa",
        path: "/more/products#sowa",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.appPod,
        name: "aplikacja podatkowa",
        alt: "logo aplikacja podatkowa",
        path: "/more/products#aplikacja podatkowa",
        pathMobile: "/more/products",
      },
    ],
  },
  {
    name: {
      extended: "Gospodarka przestrzenna",
      short: "gp",
      superShort: "gp",
    },
    fileName: true,
    icon: {
      src: industriesIcon.gp,
      alt: "ikona gospodarka przestrzenna",
    },
    text: [
      `Aplikacja SZOPP automatyzuje proces opracowywania i publikowania planów zagospodarowania przestrzennego. SZOPP wspomaga wiązanie elementów planów zagospodarowania przestrzennego z elementami uchwały, weryfikuje poprawność wprowadzonych powiazań oraz generuje gotowe dokumenty wyrysów i wypisów.`,
      `Aplikacja GPT oprócz integracji z wieloma źródłami danych, pozwala na wyświetlanie danych z rejestrów, spraw oraz ewidencji utworzonych w aplikacji SOWA. Dzięki szerokiemu wyborowi narzędzi użytkownik może w łatwy sposób wybrać sąsiednie działki, wyświetlić i pobrać atrybuty wskazanych obiektów, wykonać analizy przestrzenne (przecinanie, zawieranie, bufor), ustalić położenie działki w Miejscowym Planie Zagospodarowania Przestrzennego lub wyszukać działkę w Ewidencji Gruntów i Budynków.`,
      `Aplikacja GPT umożliwia tworzenie portali dostępnych w wewnętrznej sieci urzędu z zakresem warstw, narzędzi oraz danych niezbędnych do codziennej pracy – tworzenia planów, generowania wypisów i wyrysów, przeprowadzania analiz na danych z wielu wydziałów.`,
      `Portal publiczny GPT udostępnia tematyczne informacje dla mieszkańców oraz inwestorów – aktualne załączniki rastrowe MPZP, wektorowe zasięgi MPZP, linie regulacyjne, atrybuty opisowe poszczególnych stref, wyszukiwarki działek i MPZP.`,
    ],
    products: [
      {
        src: productsLogo.gpt,
        name: "gpt",
        alt: "logo gpt",
        path: "/more/products#gpt",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.isdp,
        name: "isdp",
        alt: "logo isdp",
        path: "/more/products#isdp",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.sowa,
        name: "sowa",
        alt: "logo sowa",
        path: "/more/products#sowa",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.szopp,
        name: "szopp",
        alt: "logo szopp",
        path: "/more/products#szopp",
        pathMobile: "/more/products",
      },
    ],
  },
  {
    name: {
      extended: "Infrastruktura techniczna",
      short: "it",
      superShort: "it",
    },
    fileName: false,
    icon: {
      src: industriesIcon.it,
      alt: "ikona infrastruktura techniczna",
    },
    text: [
      `Moduł Dynamicznych ewidencji SOWA daje pełną swobodę w tworzeniu rejestrów i ewidencji w zakresie wyglądu formularzy obiektów i wyszukiwania oraz zakresu danych gromadzonych w ramach ewidencji.`,
      `Aplikacja GPT oprócz integracji z wieloma źródłami danych, pozwala na wyświetlanie danych z rejestrów, spraw oraz ewidencji utworzonych w aplikacji SOWA. Dzięki szerokiemu wyborowi narzędzi użytkownik może w łatwy sposób wybrać sąsiednie działki, wyświetlić i pobrać atrybuty wskazanych obiektów, wykonać analizy przestrzenne (przecinanie, zawieranie, bufor), ustalić położenie działki w MPZP lub wyszukać działkę w Ewidencji Gruntów i Budynków.`,
      `Aplikacja GPT umożliwia tworzenie portali dostępnych w wewnętrznej sieci urzędu z zakresem warstw, narzędzi oraz danych niezbędnych do codziennej pracy – prowadzenia ewidencji dróg i obiektów mostowych, prowadzenia ewidencji sieci uzbrojenia terenu, linii kolejowych itp.`,
      `Portal publiczny GPT udostępnia tematyczne informacje dla mieszkańców, turystów oraz inwestorów – sieci dróg, linii kolejowych, dostępu do sieci uzbrojenia terenu itp.`,
    ],
    products: [
      {
        src: productsLogo.gpt,
        name: "gpt",
        alt: "logo gpt",
        path: "/more/products#gpt",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.isdp,
        name: "isdp",
        alt: "logo isdp",
        path: "/more/products#isdp",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.sowa,
        name: "sowa",
        alt: "logo sowa",
        path: "/more/products#sowa",
        pathMobile: "/more/products",
      },
    ],
  },
  {
    name: {
      extended: "Inwestycje",
      short: "inw",
      superShort: "inw",
    },
    fileName: true,
    icon: {
      src: industriesIcon.inw,
      alt: "ikona inwestycje",
    },
    text: [
      `Moduł Oferty inwestycyjne SOWA wspiera pracowników urzędu przy tworzeniu, gromadzeniu i edycji ofert inwestycyjnych. Utworzone w systemie oferty w zależności od stanu – ich kompletności mogą zostać przyporządkowane do jednego z trzech rejestrów (w opracowaniu, kompletne, archiwalne).`,
      `Do każdej z ofert może być dołączonych wiele zdjęć oraz map tematycznych takich, jak: mapa lokalizacyjna, mapa własności i użytkowania wieczystego, mapa komunikacji, mapa zagospodarowania przestrzennego, mapa użytkowania terenu, mapa ukształtowania terenu, ortofotomapa z granicami oferty inwestycyjnej.`,
      `Aplikacja GPT oprócz integracji z wieloma źródłami danych, pozwala na wyświetlanie danych z rejestrów, spraw oraz ewidencji utworzonych w aplikacji SOWA. Dzięki szerokiemu wyborowi narzędzi użytkownik może w łatwy sposób wybrać sąsiednie działki, wyświetlić i pobrać atrybuty wskazanych obiektów, wykonać analizy przestrzenne (przecinanie, zawieranie, bufor), ustalić położenie działki w MPZP lub wyszukać działkę w Ewidencji Gruntów i Budynków.`,
      `Aplikacja GPT umożliwia tworzenie portali dostępnych w wewnętrznej sieci urzędu z zakresem warstw, narzędzi oraz danych niezbędnych do codziennej pracy.`,
      `Portal publiczny GPT udostępnia tematyczne informacje dla mieszkańców oraz inwestorów – lokalizacje ofert, ich atrybuty opisowe, zdjęcia, załączone dokumenty, filmy oraz inne istotne informacje – działki, MPZP, ulice.`,
    ],
    products: [
      {
        src: productsLogo.gpt,
        name: "gpt",
        alt: "logo gpt",
        path: "/more/products#gpt",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.isdp,
        name: "isdp",
        alt: "logo isdp",
        path: "/more/products#isdp",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.sowa,
        name: "sowa",
        alt: "logo sowa",
        path: "/more/products#sowa",
        pathMobile: "/more/products",
      },
    ],
  },
  {
    name: {
      extended: "Ochrona środowiska",
      short: "os",
      superShort: "os",
    },
    fileName: false,
    icon: {
      src: industriesIcon.os,
      alt: "ikona ochrona środowiska",
    },
    text: [
      `Moduł Środowisko SOWA daje możliwość tworzenia kart Publicznie Dostępnego Wykazu Decyzji o Środowisku dla prowadzonych spraw, które następnie są publikowane w serwisach www. Moduł pozwala na prowadzenie postępowań środowiskowych oraz generowanie raportów specyficznych dla wydziałów środowiska.`,
      `Aplikacja PIECE wspomaga proces obsługi wniosków o dofinansowanie wymiany źródeł ogrzewania oraz inwentaryzację źródeł ogrzewania w mieście. Umożliwia prowadzenie spraw, usprawnia proces wydawania decyzji, generowania umów, wykonywania rozliczeń, przeprowadzania kontroli, wykonywania raportów oraz obliczania emisji równoważnej, PM10, PM2,5 oraz CO2.`,
      `Aplikacja GPT oprócz integracji z wieloma źródłami danych, pozwala na wyświetlanie danych z rejestrów, spraw oraz ewidencji utworzonych w aplikacji SOWA. Dzięki szerokiemu wyborowi narzędzi użytkownik może w łatwy sposób wybrać sąsiednie działki, wyświetlić i pobrać atrybuty wskazanych obiektów, wykonać analizy przestrzenne (przecinanie, zawieranie, bufor), ustalić położenie działki w MPZP lub wyszukać działkę w Ewidencji Gruntów i Budynków.`,
      `Aplikacja GPT umożliwia tworzenie portali dostępnych w wewnętrznej sieci urzędu z zakresem warstw, narzędzi oraz danych niezbędnych do codziennej pracy – lokalizacje inwestycji, MPZP, zabytki, obszary chronione, POŚ itp.`,
      `Portal publiczny GPT udostępnia tematyczne informacje dla mieszkańców, turystów oraz inwestorów – lokalizacje obiektów i obszarów chronionych, inwentaryzacje źródeł ogrzewania, zabytki, MPZP, mapy akustyczne, mapy osuwisk itp.`,
    ],
    products: [
      {
        src: productsLogo.gpt,
        name: "gpt",
        alt: "logo gpt",
        path: "/more/products#gpt",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.isdp,
        name: "isdp",
        alt: "logo isdp",
        path: "/more/products#isdp",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.sowa,
        name: "sowa",
        alt: "logo sowa",
        path: "/more/products#sowa",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.piece,
        name: "piece",
        alt: "logo piece",
        path: "/more/products#piece",
        pathMobile: "/more/products",
      },
    ],
  },
  {
    name: {
      extended: "PINB",
      short: "pinb",
      superShort: "pinb",
    },
    fileName: true,
    icon: {
      src: industriesIcon.pinb,
      alt: "ikona pinb",
    },
    text: [
      `SOWA PINB to moduł umożliwiający współpracę z Powiatowym Inspektorem Nadzoru Budowlanego. Moduł PINB jest skonfigurowany jako dodatkowa jednostka organizacyjna poza strukturami urzędu, z własnymi użytkownikami i możliwością podziału na własną strukturę organizacyjną.`,
      `Powiatowy Inspektor Nadzoru Budowlanego otrzymując pismo dodaje je do swojego rejestru pism przychodzących, a następnie może je dodać do kolejnego rejestru pism (PINB-SO.510 Rejestr pozwoleń na budowę, rozbudowę, rozbiórkę budynków otrzymane od organu AB, PINB-SO.511 Rejestr zgłoszeń zamiaru przystąpienia do wykonania robót budowlanych nie wymagających pozwolenia otrzymane od organu AB).`,
      `Moduł PINB umożliwia korzystanie z własnych ewidencji i rejestrów, które są w pełni funkcjonalne i nie różnią się od analogicznych struktur w pozostałych modułach SOWA.`,
      `Dane sprawy/pisma pobierane są automatycznie podczas przekazywania z Wydziału Archtektury.`,
    ],
    products: [
      {
        src: productsLogo.sowa,
        name: "sowa",
        alt: "logo sowa",
        path: "/more/products#sowa",
        pathMobile: "/more/products",
      },
    ],
  },
  {
    name: {
      extended: "Zabytki",
      short: "zab",
      superShort: "zab",
    },
    fileName: false,
    icon: {
      src: industriesIcon.zab,
      alt: "ikona zabytki",
    },
    text: [
      `Aplikacja EOZ służy do prowadzenie Gminnej Ewidencji Zabytków (zabytki + archeologia), wraz z bazą zabytkowych cmentarzy oraz archiwum. Korzystanie z aplikacji jest możliwe przez przeglądarkę internetową www. W ramach każdej z ewidencji Aplikacja przechowuje oraz umożliwia modyfikację specyficznych atrybutów.`,
      `Moduł Zabytki SOWA pozwala na prowadzenie rejestrów zabytków z podziałami na Gminną Ewidencję Zabytków oraz Rejestr Zabytków. Zawiera on rozbudowany zestaw atrybutów, jakimi można opisać obiekt. W ramach wpisu można również dodawać załączniki z dokumentacją oraz dokumentację zdjęciową.`,
      `Aplikacja GPT oprócz integracji z wieloma źródłami danych, pozwala na wyświetlanie danych z rejestrów, spraw oraz ewidencji utworzonych w aplikacji SOWA. Dzięki szerokiemu wyborowi narzędzi użytkownik może w łatwy sposób wybrać sąsiednie działki, wyświetlić i pobrać atrybuty wskazanych obiektów, wykonać analizy przestrzenne (przecinanie, zawieranie, bufor), ustalić położenie działki w MPZP lub wyszukać działkę w Ewidencji Gruntów i Budynków.`,
      `Aplikacja GPT umożliwia tworzenie portali dostępnych w wewnętrznej sieci urzędu z zakresem warstw, narzędzi oraz danych niezbędnych do codziennej pracy – lokalizacje inwestycji, MPZP, zabytki, obszary chronione, POŚ itp.`,
      `Portal publiczny GPT udostępnia tematyczne informacje dla mieszkańców, turystów oraz inwestorów – lokalizacje obiektów i obszarów chronionych, zabytki, MPZP itp.`,
    ],
    products: [
      {
        src: productsLogo.gpt,
        name: "gpt",
        alt: "logo gpt",
        path: "/more/products#gpt",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.isdp,
        name: "isdp",
        alt: "logo isdp",
        path: "/more/products#isdp",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.sowa,
        name: "sowa",
        alt: "logo sowa",
        path: "/more/products#sowa",
        pathMobile: "/more/products",
      },
      {
        src: productsLogo.eoz,
        name: "eoz",
        alt: "logo eoz",
        path: "/more/products#eoz",
        pathMobile: "/more/products",
      },
    ],
  },
  {
    name: {
      extended: "Integracje",
      short: "int",
      superShort: "int",
    },
    icon: {
      src: industriesIcon.int,
      alt: "ikona integracje",
    },
    text: [
      `Prawidłowo zaprojektowane oraz wdrożone integracje między systemami zapewniają możliwość łączenia danych przestrzennych pochodzących z wielu źródeł i równoczesne wykorzystywanie ich przez różnych użytkowników i do celów poszczególnych aplikacji.`,
      `Niezależnie od gotowych rozwiązań, w oparciu o nasze kompetencje i doświadczenia realizujemy projekty polegające na integracji systemów i aplikacji np.:`,
      [
        `integracje wymiany informacji bezpośrednio pomiędzy bazami danych,`,
        `interfejs integracyjny IUIP umożliwiający pobieranie słownika działek, ulic, danych ewidencyjnych, pełne dane własnościowe i umożliwiający zapis danych (np. spraw) na mapie,`,
        `integracja w zakresie uwierzytelniania użytkowników – LDAP,`,
        `integracje z systemami obiegu dokumentów,`,
        `integracje z aplikacjami prezentującymi dane 3D,`,
        `integracja z systemami podatkowo-księgowymi.`,
      ],
    ],
    products: [],
  },
];

export { constContent, industries };
