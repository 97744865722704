import React, { useState } from "react";
import { Accordion } from "react-accessible-accordion";
import { AccordionNavItem } from "utils";
import IndustryCard from "./IndustryCard";
import { constContent } from "staticContent/more/industry";
import { getAccordionItems } from "helpers/accordionNavItem.helpers";

const AccordionPanel = ({ items }) => {
  const [isActiveItem, setIsActiveitem] = useState(getAccordionItems(items));

  const handleClick = it => {
    setIsActiveitem({ ...isActiveItem, [it]: !isActiveItem[it] });
  };

  const accordionItems = items.map((item, index) => (
    <AccordionNavItem
      key={index}
      name={item.name}
      pic={item.icon}
      onClick={handleClick}
      isActive={isActiveItem}
    >
      <IndustryCard
        {...item}
        subtitle={constContent.offerProducts}
        btnName={constContent.btn}
      />
    </AccordionNavItem>
  ));

  return (
    <Accordion allowMultipleExpanded allowZeroExpanded>
      {accordionItems}
    </Accordion>
  );
};

export default AccordionPanel;
