import React, { useEffect, useState } from "react";
import { CardParagraph, CardList } from "./IndustryCardParagraphs";
import IndustryCardBtn from "./IndustryCardBtn";
import IndustryCardIcons from "./IndustryCardIcons";
import { industriesDocs } from "routes/docsPaths";

const IndustryOffer = props => {
  const { name, fileName, text, products, subtitle, btnName } = props;
  const [pageWidth, setPageWidth] = useState(0);

  const paragraps = text.map((paragraph, index) => {
    if (typeof paragraph === "string") {
      return <CardParagraph key={index * 333} text={paragraph} />;
    } else {
      return <CardList key={index * 333} list={paragraph} />;
    }
  });

  const icons = products.map((product, index) => (
    <IndustryCardIcons key={index} {...product} pageWidth={pageWidth} />
  ));

  useEffect(() => {
    setPageWidth(window.screen.width);
  }, []);

  return (
    <>
      <h3 className="industry__offer-title">{name.extended}</h3>
      {paragraps}
      <div className="industry__offer-products-wrapper">
        {products.length > 0 && (
          <span className="industry__offer-products-title">{subtitle}</span>
        )}
        <div className="industry__offer-products-icons">{icons}</div>
      </div>
      {fileName && (
        <IndustryCardBtn path={industriesDocs[name.short]} text={btnName} />
      )}
    </>
  );
};

export default IndustryOffer;
