import React from "react";
import { Check } from "utils";

const CardParagraph = ({ text }) => (
  <p className="industry__offer-text">{text}</p>
);

const CardList = ({ list }) => (
  <ul className="industry__offer-text--list">
    {list.map(item => (
      <li key={item} className="industry__offer-text--list-item">
        <Check className="product-block__list-item--check" size={40} />
        {item}
      </li>
    ))}
  </ul>
);

export { CardParagraph, CardList };
