import React from "react";
import { Arrow } from "utils";
import { arrowSettings } from "constants/industry.constants";

const IndustryTile = ({ name, icon, handleClick, active }) => (
  <div
    key={name.short}
    className={`industry__tile ${active === name.short ? "active" : ""}`}
    onKeyDown={() => handleClick(name.short)}
    tabIndex="0"
    role="button"
    onClick={() => handleClick(name.short)}
  >
    <div className="industry__tile-icon">
      <img loading="lazy" src={icon.src} alt={icon.alt} />
    </div>
    <h2 className="industry__tile-title">{name.extended}</h2>
    <Arrow {...arrowSettings} />
  </div>
);

export default IndustryTile;
