import React from "react";
import Layout from "utils/Layout";
import Industry from "components/Industry";
import "styles/components/industry.scss";
import { constContent } from "staticContent/more/industry";

const IndexPage = ({ location: { hash } }) => (
  <Layout subTitle={constContent.pageTitle}>
    <Industry industry={hash} />
  </Layout>
);

export default IndexPage;
