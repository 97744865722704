import React from "react";
import { Button } from "utils";

const IndustryCardBtn = ({ path, text }) => (
  <a href={path} rel="noopener noreferrer" target="_blank">
    <Button className="industry__btn" children={text} />
  </a>
);

export default IndustryCardBtn;
