import React from "react";
import { Link } from "gatsby";

const IndustryCardIcons = ({ src, name, alt, path, pathMobile, pageWidth }) => (
  <div className="industry__products-icons-wrapper">
    <Link to={pageWidth > 600 ? path : pathMobile}>
      <img className="industry__icon-img" loading="lazy" src={src} alt={alt} />
      <p className="industry__icon-name">{name}</p>
    </Link>
  </div>
);

export default IndustryCardIcons;
