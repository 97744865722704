import React, { useEffect, useRef, useState } from "react";
import AccordionPanel from "./IndustryMobile";
import IndustryTile from "./IndustryTile";
import IndustryCard from "./IndustryCard";
import SwitchComponents from "services/SwitchComponents";
import { constContent, industries } from "staticContent/more/industry";

const industryCards = industries.map((industry, index) => (
  <IndustryCard
    key={index}
    {...industry}
    subtitle={constContent.offerProducts}
    btnName={constContent.btn}
  />
));

const Industry = ({ industry }) => {
  const [isActive, setIsActive] = useState("");
  const [pageWidth, setPageWidth] = useState(0);
  const industryRef = useRef();

  useEffect(() => {
    setIsActive(industry.substr(industry.indexOf("#") + 1));
  }, [industry]);

  useEffect(() => {
    industryRef.current &&
      industryRef.current.scrollIntoView({ behavior: "smooth" });
  }, [isActive]);

  useEffect(() => {
    setPageWidth(window.screen.width);
  }, []);

  const handleTileClick = activeTile => {
    industryRef.current &&
      industryRef.current.scrollIntoView({ behavior: "smooth" });
    setIsActive(activeTile);
  };

  const industryTiles = industries.map((industry, index) => (
    <IndustryTile
      key={index}
      {...industry}
      handleClick={handleTileClick}
      active={isActive}
    />
  ));

  return (
    <>
      <section className="section hero industry">
        <h1 className="hero__banner-text">{constContent.pageTitle}</h1>
      </section>
      {pageWidth < 600 ? (
        <AccordionPanel items={industries} />
      ) : (
        <>
          <nav className="industry__tiles-wrapper">{industryTiles}</nav>
          <section className="section industry__offer" ref={industryRef}>
            <SwitchComponents active={isActive}>
              {industryCards}
            </SwitchComponents>
          </section>
        </>
      )}
    </>
  );
};

export default Industry;
